import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { Box, Typography, useTheme } from "@mui/material";
import React, { useContext, useEffect, useState } from 'react';
import ReactSpeedometer from "react-d3-speedometer";
import { useNavigate } from 'react-router-dom';
import LineChart from "../../components/LineChart_dashboard";
import { FirebaseContext } from "../../data/provide_firestore";
import { tokens } from "../../theme";

const Overview_Dashboard = ({ company }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  var topic = "";

  const [liGoogleColor, setGoogleColor] = useState({ color: colors.grey[100] });
  const [allData, setAllData] = useState([]);
  const [getNumPosts, setNumPosts] = useState(15);

  const {
    liDataFire,
    liWifiDataFire,
    liUptimeWireFire,
    liUptimeWifiFire,
    liSpeedFire,
    liWifiSpeedFire,
    liAliasFire,
    trigger,
  } = useContext(FirebaseContext);

  function GetData() {
    let lotData = [];
    let DL_wire = [];
    if (liDataFire) {
      console.log(liDataFire);
      const keys = Object.keys(liDataFire);
      keys.map((scanner) => {
        console.log(scanner);
        var connection = null;
        var icon = null;
        var speed_row = [];

        if (liAliasFire) {
          const alias_row = Object.values(liAliasFire[scanner]);
          if (alias_row[1]) {
            topic = alias_row[1];
          } else {
            topic = alias_row[1];
          }
        } else {
          topic = scanner;
        }
        const speed_eth_row = Object.values(liSpeedFire[scanner]);
        const speed_wifi_row = Object.values(liWifiSpeedFire[scanner]);

        // WIRE
        const getMaxValue = (arr) => Math.round(Math.max(...arr));
        const DL_values_wire =
          speed_eth_row
            ?.filter((row) => row.speedtest[0])
            ?.map((row) => row.speedtest[0]) ?? [];
        const MaxDL_wire =
          DL_values_wire.length > 0 ? getMaxValue(DL_values_wire) : 0;
        const DLnow_wire =
          speed_eth_row?.[speed_eth_row.length - 1]?.speedtest[0] ?? 0;

        // WIFI
        const DL_values_wifi =
          speed_wifi_row
            ?.filter((row) => row.speedtest[0])
            ?.map((row) => row.speedtest[0]) ?? [];
        const MaxDL_wifi =
          DL_values_wifi.length > 0 ? getMaxValue(DL_values_wifi) : 0;
        const DLnow_wifi =
          speed_wifi_row?.[speed_wifi_row.length - 1]?.speedtest[0] ?? 0;

        lotData.push({
          scanner: scanner,
          maxdl_wire: MaxDL_wire,
          dl_wire: DLnow_wire,
          maxdl_wifi: MaxDL_wifi,
          dl_wifi: DLnow_wifi,
          topic: "Demo Room 205",
          connection: connection,
          icon: icon,
        });
        lotData.push({
          scanner: scanner,
          maxdl_wire: MaxDL_wire,
          dl_wire: DLnow_wire,
          maxdl_wifi: MaxDL_wifi,
          dl_wifi: DLnow_wifi,
          topic: "Demo Office Floor 4",
          connection: connection,
          icon: icon,
        });

        setAllData(lotData);
      });
    }
  }

  useEffect(() => {
    GetData();
  }, [liDataFire, liWifiSpeedFire, liSpeedFire, trigger]);

  return (
    <Box ml="20px" mr="20px" mt="-10px">
      {/* HEADER */}

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="135px"
        gap="20px"
      >
        {allData.map((y, i) => (
          <>
            <Box
              gridColumn="span 8"
              gridRow="span 2"
              backgroundColor={colors.primary[400]}
            >
              <Box
                mt="25px"
                p="0 30px"
                display="flex "
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <Typography
                    variant="h4"
                    sx={{ color: colors.greenAccent[500] }}
                  >
                    {y.topic}
                  </Typography>
                  <div style={{ marginTop: "10px", marginBottom: "-20px" }}>
                    <div
                      style={{ backgroundColor: colors.redAccent[300] }}
                      class="redbox"
                    ></div>
                    <span class="text">Ethernet</span>
                    <div
                      style={{ backgroundColor: colors.blueAccent[300] }}
                      class="greenbox"
                    ></div>
                    <span class="text">Wireless</span>
                  </div>

                  <div
                    key={y.scanner + "div"}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {y.icon}&nbsp;&nbsp; {y.connection}
                  </div>
                </Box>
                <Box>
                  <Typography variant="h5" fontWeight="bold" sx={liGoogleColor}>
                    Latency ms
                  </Typography>

                  {/* <Typography variant="h5" fontWeight="bold" sx={liGoogleColor}>
            {y.data[(y.data.length)-1]?.ping[4]}
            </Typography> */}
                </Box>
              </Box>
              <Box height="250px" m="-30px 0 0 0">
                <LineChart isDashboard={true} scanner={y.scanner} />
              </Box>
            </Box>
            <Box
              gridColumn="span 2"
              gridRow="span 2"
              backgroundColor={colors.primary[400]}
              p="10px"
            >
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography variant="h4" fontWeight="600">
                  Ethernet
                </Typography>
              </Box>
              <Typography mt="-30px">
                <CloudDownloadIcon
                  sx={{ color: colors.greenAccent[500], fontSize: "36px" }}
                />
              </Typography>

              <Box
                mt="-0px"
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <ReactSpeedometer
                  width={250}
                  height={200}
                  forceRender={true}
                  ringWidth={5}
                  minValue={0}
                  maxValue={y.maxdl_wire}
                  value={y.dl_wire}
                  key={"speed" + i}
                  maxSegmentLabels={0}
                  segments={3000}
                  needleColor="steelblue"
                  currentValueText=""
                />
              </Box>
              <Box
                mt="-50px"
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Typography
                  variant="h4"
                  align="center"
                  fontWeight="bold"
                  sx={{ color: colors.grey[100] }}
                >
                  {" " + y.dl_wire + " Mbps"}
                </Typography>
                <Typography variant="h7">
                  {"Max todat: " + y.maxdl_wire + " mbps"}
                </Typography>
              </Box>
            </Box>
            <Box
              gridColumn="span 2"
              gridRow="span 2"
              backgroundColor={colors.primary[400]}
              p="10px"
            >
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography variant="h4" fontWeight="600">
                  Wireless
                </Typography>
              </Box>
              <Typography mt="-30px">
                <CloudDownloadIcon
                  sx={{ color: colors.greenAccent[500], fontSize: "36px" }}
                />
              </Typography>

              <Box
                mt="-0px"
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <ReactSpeedometer
                  width={250}
                  height={200}
                  forceRender={true}
                  ringWidth={5}
                  minValue={0}
                  maxValue={y.maxdl_wifi}
                  value={y.dl_wifi}
                  key={"speed2" + i}
                  maxSegmentLabels={0}
                  segments={3000}
                  needleColor="steelblue"
                  currentValueText=""
                />
              </Box>
              <Box
                mt="-50px"
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Typography
                  variant="h4"
                  align="center"
                  fontWeight="bold"
                  sx={{ color: colors.grey[100] }}
                >
                  {" " + y.dl_wifi + " Mbps"}
                </Typography>
                <Typography variant="h7">
                  {"Max todat: " + y.maxdl_wifi + " mbps"}
                </Typography>
              </Box>
            </Box>
          </>
        ))}
      </Box>
    </Box>
  );
};

export default Overview_Dashboard;
