import { useTheme } from "@mui/material";
import { ResponsiveLine } from "@nivo/line";
import React, { useEffect, useState } from 'react';
import { tokens } from "../theme";

const LineChart = ({ isCustomLineColors = false, isDashboard = false, sqlData }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [liSql, setSql] = useState([{"id": "WAN", color: "hsl(149, 70%, 50%)", "data": [{"x": 0, "y": 0}]}]);
  const [liMax, setMax] = useState(10);
  const [liMin, setMin] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
        let waitdata = await sqlData;
        if(waitdata){
          var Google = []
          var Time = []
          var min = 0
          var max = 0

          waitdata.map(sqlrows => {
            Google.push(sqlrows?.ping[4])
            Time.push(sqlrows?.dt[1])
         })
         max = Math.max(...Google)
         min = Math.min(...Google)
         let data = Google.map((y, i) => ({ 
            x: `${Time[i]}`, y 
          }))

         const diff = max*0.5
         setMin(min-diff)
         setMax(max*1.5)
         let test = [{"id": "Google", color: tokens("dark").blueAccent[300], "data": data}]
         setSql(test)

        }
      }
      
        fetchData()
        .catch(console.error);
      },[sqlData])

  return (
    <ResponsiveLine
      data={liSql}
          theme={{
            axis: {
              domain: {
                line: {
                  stroke: colors.grey[100],
                },
              },
              legend: {
                text: {
                  fill: colors.grey[100],
                },
              },
              ticks: {
                line: {
                  stroke: colors.grey[100],
                  strokeWidth: 1,
                },
                text: {
                  fill: colors.grey[100],
                },
              },
            },
            legends: {
              text: {
                fill: colors.grey[100],
              },
            },
            tooltip: {
              container: {
                color: colors.primary[500],
              },
            },
          }}
          colors={isDashboard ? { datum: "color" } : { scheme: "nivo" }} // added
          margin={{ top: 50, right: 30, bottom: 50, left: 30 }}
          xScale={{ type: "point" }}
          yScale={{
            type: "linear",
            min: liMin,
            max: liMax,
            stacked: true,
            reverse: false,
          }}
          yFormat=" >-.2f"
          curve="catmullRom"
          axisTop={null}
          axisRight={null}
          axisBottom={{
            orient: "bottom",
            tickSize: 0,
            tickPadding: 5,
            tickRotation: 0,
            legend: isDashboard ? undefined : "transportation", // added
            legendOffset: 36,
            legendPosition: "middle",
          }}
          axisLeft={{
            orient: "left",
            tickValues: 5, // added
            tickSize: 3,
            tickPadding: 5,
            tickRotation: 0,
            legend: isDashboard ? undefined : "count", // added
            legendOffset: -40,
            legendPosition: "middle",
          }}
          enableGridX={false}
          enableGridY={false}
          pointSize={0}
          pointColor={{ theme: "background" }}
          pointBorderWidth={2}
          pointBorderColor={{ from: "serieColor" }}
          pointLabelYOffset={-12}
          useMesh={true}
          legends={[]}
          animate={false}
        />
      );
    };
    
    export default LineChart;