import { Box, CircularProgress, useTheme, FormControlLabel, Switch, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FirebaseContext } from '../../data/provide_firestore';
import { collection, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import db from '../../firebase_config';
import { tokens } from "../../theme";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebase_config";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import { sv } from 'date-fns/locale';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';


const Settings = ({ company, id, site }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();
  const [scan_raw, setScan_raw] = useState([])
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [getWS, setWS] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setLoading(true);
    async function fetchData() {

      await GetPen();
    }

    fetchData();
  }, [id]);



  // functions to handle opening and closing the Dialog
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

const handleSwitchChange = (event) => {
    if(event.target.name === "ws"){
      setWS(event.target.checked)
    }
    
  };

  const handesave = async (event) => {
    event.preventDefault();
    let formattedDate = format(startDate, "yyMMdd'T'HHmm");
    console.log(formattedDate);
    setOpen(false);
    let payload = {
      ws_start: formattedDate
    }
    await updateDoc(doc(db, "Scanners", id), payload);
  }

  const bigQuery = async () => {
    const doBigQuery = httpsCallable(functions, 'get_pen');
    var data = {
      scanner: id,
    };
    try {
      const fullResult = await doBigQuery(data);
      return fullResult.data;
    } catch (error) {
      console.error('Error in bigQuery:', error);
      return null;
    }
  };

async function GetPen() {
  const bigQueryResult = await bigQuery();

  if(bigQueryResult?.[0]){
    const jsonArray = JSON.parse(bigQueryResult[0][0].data)
    var data = [];
    var oldip = "";
    var settings = "settings";
    
    if (Array.isArray(jsonArray)) {
      jsonArray.map((key) => {
        var [ip, port, type, objectString] = key.split("::");
        var jsonString = objectString.replace(/'/g, '"');
        var object = JSON.parse(jsonString);
    
        if (oldip === ip) {
          ip = "";
          settings = "";
        } else {
          oldip = ip;
          settings = "settings";
        }
    
        data.push({
          ip: ip,
          port: port,
          type: type,
          exptype: object.type,
          id: object.id,
          settings: settings,
        });
      });
    } else {
      console.error("jsonArray is not an array.");
    }
    
    
    
    if(bigQueryResult){
      setScan_raw(data)
      setLoading(false);
      }
      else{
        setScan_raw(["No exploits found",])
      }
    }
    
    
      

  
}
var items = <tr></tr>

if(scan_raw?.[0]){
  items = scan_raw.map(key => (
    <tr>
    <td align="center" className={key.settings} style={{color: colors.grey[100],}}><h4 className='settings' style={{color: colors.grey[100],}}>{key.ip}</h4></td>
    <td align="center" className={key.settings} style={{color: colors.grey[100],}}><h4 className='settings' style={{color: colors.grey[100],}}>{key.port}</h4></td>
    <td align="center" className={key.settings} style={{color: colors.grey[100],}}><h4 className='settings' style={{color: colors.grey[100],}}>{key.type}</h4></td>
    <td align="center" className={key.settings} style={{color: colors.grey[100],}}><h4 className='settings' style={{color: colors.grey[100],}}>{key.exptype}</h4></td>
    <td align="center" className={key.settings} style={{color: colors.grey[100],}}><h4 className='settings' style={{color: colors.grey[100],}}>{key.id}</h4></td>
      </tr>
  ));
}



  return (
    <section>
      <div className="pen_box" style={{ backgroundColor: colors.primary[400],backgroundImage: `url(https://analyzer.hs7.se/HS_white_op2.png)`, backgroundSize:"70%",backgroundPositionX: "25%", backgroundRepeat: "no-repeat", paddingBottom:"50px" }}>
        <div>
          <div>
                        <h3 className="settings has-text-centered" style={{color: colors.grey[100],}}>Penetration Report</h3>
                        <hr style={{width:"20%" }}></hr>
                        
                        
                        {loading ? (
          <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              bgcolor: "rgba(0,0,0,0)",
              height: "50vh",
            }}
          >
            <CircularProgress sx={{ color: colors.greenAccent[500] }} />
            <h3
                  className="settings has-text-centered"
                  style={{ color: colors.grey[100] }}
                >
                  Fetching Data...
                </h3>
          </Box>

                </>
        ) :(
          <>
                        <table style={{width:"100%"}}>
  <tr>
    <th className='settings'><h4 className='settings' style={{color: colors.grey[100], fontSize : "18px"}}>IP</h4></th>
    <th className='settings'><h4 className='settings' style={{color: colors.grey[100],fontSize : "18px"}}>TCP Port</h4></th>
    <th className='settings'><h4 className='settings' style={{color: colors.grey[100],fontSize : "18px"}}>Protocol</h4></th>
    <th className='settings'><h4 className='settings' style={{color: colors.grey[100],fontSize : "18px"}}>Exploit Type</h4></th>
    <th className='settings'><h4 className='settings' style={{color: colors.grey[100],fontSize : "18px"}}>Exploit ID</h4></th>
  </tr>
{items}
                
                </table>
                </>)}
                </div>  
            </div>
            </div>
        
</section>
  );
};

export default Settings;
