import { useTheme } from "@mui/material";
import { ResponsiveLine } from "@nivo/line";
import React, { useEffect, useState } from 'react';
import { tokens } from "../theme";

const LineChart_Small = ({
  chdata,
  isCustomLineColors = false,
  isDashboard = true,
  sqlData,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [liSql, setSql] = useState([
    { id: "WAN", color: "hsl(149, 70%, 50%)", data: [{ x: 0, y: 0 }] },
  ]);
  const [liMax, setMax] = useState(10);
  const [liMin, setMin] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      let waitdata = await sqlData;
      if (waitdata) {
        let data = [];
        var Gateway = [];
        var Stockholm = [];
        var Custom = [];
        var Newyork = [];
        var Time = [];
        var min = 0;
        var max = 0;

        var lineColor = tokens("dark").blueAccent[300];

        waitdata.map((sqlrows) => {
          Gateway.push(sqlrows.ping[5]);
          Stockholm.push(sqlrows.ping[0]);
          Custom.push(sqlrows.ping[1]);
          Newyork.push(sqlrows.ping[3]);
          Time.push(sqlrows.dt[1]);
        });

        if (chdata === "Gateway") {
          max = Math.max(...Gateway);
          min = Math.min(...Gateway);
          data = Gateway.map((y, i) => ({ x: `${Time[i]}`, y }));
        } else if (chdata === "Stockholm") {
          max = Math.max(...Stockholm);
          min = Math.min(...Stockholm);
          data = Stockholm.map((y, i) => ({ x: `${Time[i]}`, y }));
        }  else if (chdata === "Newyork") {
          max = Math.max(...Newyork);
          min = Math.min(...Newyork);
          data = Newyork.map((y, i) => ({ x: `${Time[i]}`, y }));
        }
        else{
          max = Math.max(...Custom);
          min = Math.min(...Custom);
          data = Custom.map((y, i) => ({ x: `${Time[i]}`, y }));
        }

        const diff = max * 0.5;
        setMin(min - diff);
        setMax(max * 1.5);
        let lineData = [{ id: chdata, color: lineColor, data: data }];
        setSql(lineData);
      }
    };

    fetchData().catch(console.error);
  }, [sqlData]);

  return (
    <ResponsiveLine
      data={liSql}
      theme={{
        axis: {
          domain: {
            line: {
              stroke: colors.grey[0],
            },
          },
          ticks: {
            line: {
              stroke: colors.grey[400],
              strokeWidth: 0,
              display: false,
            },
          },
        },
        tooltip: {
          container: {
            color: colors.primary[500],
            display: false,
          },
        },
      }}
      colors={isDashboard ? { datum: "color" } : { scheme: "nivo" }}
      margin={{ top: 10, right: 0, bottom: 10, left: 0 }}
      xScale={{ type: "point" }}
      yScale={{
        type: "linear",
        min: liMin,
        max: liMax,
        stacked: false,
        reverse: false,
      }}
      yFormat=" >-.2f"
      curve="catmullRom"
      axisTop={null}
      axisRight={null}
      axisLeft={{
        orient: "left",
        tickValues: 0, // added
        tickSize: 0,
        tickPadding: 0,
        tickRotation: 0,
        legendOffset: -40,
        legendPosition: "middle",
      }}
      enableGridX={false}
      enableGridY={false}
      pointSize={0}
      pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      pointLabelYOffset={-12}
      useMesh={true}
      animate={false}
    />
  );
};

export default LineChart_Small;
