import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { collection, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthValue } from '../AuthContext';
import { FirebaseContext } from '../data/provide_firestore';
import db from '../firebase_config';
import { toast } from 'react-toastify';

export default function FormAddScanner({ setAddScannerModal, uid, email }) {
  const [open, setOpen] = useState(true);
  const [getCompany, setCompany] = useState(null);
  const navigate = useNavigate();
  const { currentUser } = useAuthValue();
  const { userCompany } = useContext(FirebaseContext);

  const [liid, setId] = useState("");
  const [serial, setSerial] = useState("");
  const [msg, setMsg] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    setCompany(userCompany);
  }, []);

  const AddScanner = async (e) => {
    e.preventDefault();
    try {
      const dataRef = query(
        collection(db, "Scanners"),
        where("scanner", "==", liid.toLowerCase()),
        where("serial", "==", serial.toUpperCase())
      );
      const dataSnap = await getDocs(dataRef);

      if (dataSnap.docs.length === 0) {
        setMsg("Wrong ID or Serial");
      } else if (dataSnap?.docs[0]?.data()?.company === null) {
        toast.success(`${liid.toUpperCase()} added to ${userCompany}`, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
        await updateDoc(doc(db, "Scanners", liid), {
          company: userCompany.toLowerCase(),
        });
        setOpen(false);
        setAddScannerModal(false);
        setTimeout(() => {
          navigate("/dashboard");
          window.location.reload();
        }, 2000); // Change the delay as needed
      } else {
        setMsg("Scanner already registed!");
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  const handleClose = () => {
    setAddScannerModal(false);
    setOpen(false);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle style={{ backgroundColor: "white", color: "black" }}>
          Add/Change Company to account
        </DialogTitle>
        <DialogContent style={{ backgroundColor: "white", color: "black" }}>
          {msg && <div style={{ color: "red" }}>{msg}</div>}
          <TextField
            InputProps={{ style: { color: "black" } }}
            onChange={(e) => setId(e.target.value)}
            required
            focused
            margin="dense"
            id="name"
            label="ID: "
            value={liid}
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField
            InputProps={{ style: { color: "black" } }}
            onChange={(e) => setSerial(e.target.value)}
            required
            focused
            margin="dense"
            id="name"
            label="Serial: "
            value={serial}
            type="text"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions style={{ backgroundColor: "white" }}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={AddScanner}>Add</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
