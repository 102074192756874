import { makeStyles } from "@material-ui/core/styles";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DateRangeIcon from "@mui/icons-material/DateRange";
import SettingsEthernetIcon from "@mui/icons-material/SettingsEthernet";
import SsidChartIcon from "@mui/icons-material/SsidChart";
import { Box, Button, CircularProgress, useTheme } from "@mui/material";
import Fade from "@mui/material/Fade";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Slider from "@mui/material/Slider";
import { httpsCallable } from "firebase/functions";
import moment from "moment";
import React, { forwardRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Plot from "react-plotly.js";
import { useNavigate } from "react-router-dom";
import { functions } from "../../firebase_config";
import { tokens } from "../../theme";

const History = ({ company, id, site }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const get_hour = () => {
    const now = new Date();
    const hour = now.getHours();
    const sechour = hour*60;
    return sechour
  }

  const navigate = useNavigate();
  const [liLineData, setLineData] = useState([]);
  const [liLineWiFiData, setLineWiFiData] = useState([]);
  const [liValue, setValue] = useState("wire");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [timeRange, setTimeRange] = useState([0, get_hour()]);
  const [liDlShow, setDlShow] = useState("ms");
  const [liTicks, setTicks] = useState(12);
  const [anchorTime, setAnchorTime] = useState(null);
  const [anchorEth, setAnchorEth] = useState(null);
  const [anchorMs, setAnchorMs] = useState(null);
  const [triggerTime, setTriggerTime] = useState(null);
  const [loading, setLoading] = useState(true);

  const eth_open = Boolean(anchorEth);
  const ms_open = Boolean(anchorMs);
  const time_open = Boolean(anchorTime);
  const handleClick_eth = (event) => {
    setAnchorEth(event.currentTarget);
  };
  const handleClick_ms = (event) => {
    setAnchorMs(event.currentTarget);
  };
  const handleClick_time = (event) => {
    setAnchorTime(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEth(null);
    setAnchorMs(null);
  };
  const handleTimeClose = () => {
    setAnchorTime(null);
    setTriggerTime(timeRange);
  };

  function findMaxY(data, threshold) {
    let maxY = 0;
    data.forEach((trace) => {
      trace.y.forEach((value) => {
        if (value > maxY && value < threshold) {
          maxY = value;
        }
      });
    });
    return maxY;
}
  
const threshold = 500; // set this to a value that makes sense for your data
const maxY = findMaxY(liLineData, threshold);

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <Box
      className="example-custom-input"
      onClick={onClick}
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        alignItems: "center",
        bgcolor: "rgba(0,0,0,0.2)",
        fontSize: "20px",
        color: colors.grey[100],
        cursor: "pointer",
        "&:hover": { textDecoration: "underline" },
      }}
      ref={ref}
    >
      <DateRangeIcon
        style={{ marginRight: "10px", color: colors.greenAccent[500] }}
      />
      {value}
    </Box>
  ));

  const updateSwitchChanged = (i) => (e) => {
    setValue(e.target.value);
  };
  const updateDlSwitchChanged = (i) => (e) => {
    setDlShow(e.target.value);
  };

  const useStyles = makeStyles({
    root: {
      "&$checked": {
        color: colors.grey[100], // change the color of the checked radio button
      },
    },
    checked: {},
  });

  const classes = useStyles();

  function valuetext(value) {
    const hours = Math.floor(value / 60);
    const minutes = value % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
  }
  function divtext(value) {
    const hours_start = Math.floor(value[0] / 60);
    const minutes_start = value[0] % 60;
    const hours_end = Math.floor(value[1] / 60);
    const minutes_end = value[1] % 60;
    return `${hours_start.toString().padStart(2, "0")}:${minutes_start
      .toString()
      .padStart(2, "0")} - ${hours_end
      .toString()
      .padStart(2, "0")}:${minutes_end.toString().padStart(2, "0")}`;
  }
  const updateDateChanged = (date) => {
    setSelectedDate(date);
  };

  const handleTimeRangeChange = (event, newValue) => {
    setTimeRange(newValue);
  };

  const bigQuery = async (query, table) => {
    const doBigQuery = httpsCallable(functions, "doBigQueryCall");
    const formattedDate = selectedDate
      .toLocaleDateString("sv-SE", {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
      })
      .replace(/-/g, "");
    var data = {
      query: query,
      date: formattedDate,
      scanner: id,
      table: table,
      time1:
        Math.floor(timeRange[0] / 60) +
        (timeRange[0] % 60).toString().padStart(2, "0"),
      time2:
        Math.floor(timeRange[1] / 60) +
        (timeRange[1] % 60).toString().padStart(2, "0"),
    };
    const fullResult = await doBigQuery(data);
    return fullResult.data;
  };

  const fetchData = async () => {
    if (liValue === "wifi") {
      if (liDlShow === "ms") {
        const result = await bigQuery("ap, signal_str, roaming", "wifi_data");
        setLineWiFiData(Report_line(result, ["signal_str", "roaming", "ap"]));
        const gooresult = await bigQuery(
          "ping_google, ping_gateway, ping_stockholm, ping_custom, ping_newyork, ping_dns, dns_time",
          "wifi_data"
        );
        setLineData(
          Report_line(gooresult, [
            "ping_google",
            "ping_gateway",
            "ping_stockholm",
            "ping_custom",
            "ping_newyork",
            "ping_dns",
            "dns_time"
          ])
        );
        setLoading(false);
      } else if (liDlShow === "dl") {
        const result = await bigQuery("ap, signal_str, roaming", "wifi_data");
        setLineWiFiData(Report_line(result, ["signal_str", "roaming", "ap"]));
        const dlresult = await bigQuery("dl, ul", "wifi_bw");
        setLineData(Report_line(dlresult, ["dl", "ul"]));
        setLoading(false);
      }
    } else if (liValue === "wire") {
      if (liDlShow === "ms") {
        const result = await bigQuery(
          "ping_google, ping_gateway, ping_stockholm, ping_custom, ping_newyork, ping_dns, dns_time",
          "wire_data"
        );
        setLineData(
          Report_line(result, [
            "ping_google",
            "ping_gateway",
            "ping_stockholm",
            "ping_custom",
            "ping_newyork",
            "ping_dns",
            "dns_time"
          ])
        );
        setLoading(false);
      } else if (liDlShow === "dl") {
        const result = await bigQuery("dl, ul", "wire_bw");
        setLineData(Report_line(result, ["dl", "ul"]));
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    const ticks = Math.ceil((timeRange[1] - timeRange[0]) / 60);
    setTicks(ticks);
    fetchData();
  }, [id, selectedDate, liDlShow, liValue, triggerTime]);

  const Report_line = (data, query) => {
    const dataToPlot = [];
    if (data) {
      query.map((q) => {
        var x = data[0].map((item) => {
          // Check if the time is in HHmm format
          if (/^\d{4}$/.test(item.time)) {
            return moment(item.time, "HHmm").format("HH:mm");
          } else if (/^\d{3}$/.test(item.time)) {
            return moment(item.time, "Hmm").format("HH:mm");
          } else if (/^\d{2}$/.test(item.time)) {
            return moment(item.time, "mm").format("HH:mm");
          }
          // Otherwise, assume the time is in Hmm format
          return moment(item.time, "m").format("HH:mm");
        });
        var y = data[0].map((item) => {
          return item[q];
        });
        if (q === "ap") {
          let prevAp = null;
          const roaming_dots = () => {
            let roaming_data = [];
            y.map((y_data, index) => {
              if (y_data && prevAp != y_data) {
                prevAp = y_data;
                if (index != 0) {
                  roaming_data.push(index);
                }
              }
            });
            return roaming_data;
          };
          const roaming = roaming_dots();
          if (roaming) {
            x = roaming.map((index) => x[index]);
            y = roaming.map((index) => data[0]?.[index].signal_str);

            dataToPlot.push({
              x,
              y,
              type: "scatter",
              mode: "markers",
              name: "Roaming",
              marker: {
                color: "rgb(255,65,54)",
                line: { width: 3 },
                opacity: 0.8,
                size: 12,
                symbol: "circle-open",
              },
            });
          }
        }
        if (q === "roaming") {
          let prevChan = null;
          const chan_change = () => {
            let chan_data = [];
            y.map((y_data, index) => {
              if (y_data && prevChan != y_data) {
                prevChan = y_data;
                if (index != 0) {
                  chan_data.push(index);
                }
              }
            });
            return chan_data;
          };
          const channel = chan_change();
          if (channel) {
            x = channel.map((index) => x[index]);
            y = channel.map((index) => data[0]?.[index].signal_str);

            dataToPlot.push({
              x,
              y,
              type: "scatter",
              mode: "markers",
              name: "Channel Change",
              marker: {
                color: "rgb(50,255,65)",
                line: { width: 3 },
                opacity: 0.8,
                size: 12,
                symbol: "circle-open",
              },
            });
          }
        }
        if (q != "ap" && q != "roaming") {
          dataToPlot.push({
            x,
            y,
            type: "scatter",
            mode: "lines",
            name: q === "roaming" ? "channel" : q,
          });
        }
      });
      return dataToPlot;
    }
  };

  return (
    <section>
      <div
        className="pen_box"
        style={{
          backgroundColor: colors.primary[400],
          backgroundImage: `url(https://analyzer.hs7.se/HS_white_op2.png)`,
          backgroundSize: "70%",
          backgroundPositionX: "25%",
          backgroundRepeat: "no-repeat",
          paddingBottom: "50px",
        }}
      >
        <h3
          className="settings has-text-centered"
          style={{ color: colors.grey[100] }}
        >
          History
        </h3>
        <hr style={{ width: "20%" }}></hr>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            bgcolor: "rgba(0,0,0,0.2)",
            marginTop: "20px",
          }}
        >
          <DatePicker
            onChange={updateDateChanged}
            dateFormat="yyyy-MM-dd"
            selected={selectedDate}
            customInput={<ExampleCustomInput />}
            calendarStartDay={1}
          />

          <Box
            id="time-button"
            aria-controls={time_open ? "time-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={time_open ? "true" : undefined}
            onClick={handleClick_time}
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              alignItems: "center",
              bgcolor: "rgba(0,0,0,0.2)",
              fontSize: "20px",
              color: colors.grey[100],
              cursor: "pointer",
              "&:hover": { textDecoration: "underline" },
            }}
          >
            <AccessTimeIcon
              style={{ marginRight: "10px", color: colors.greenAccent[500] }}
            />{" "}
            {divtext(timeRange)}
          </Box>

          <Menu
            id="time-menu"
            MenuListProps={{
              "aria-labelledby": "time-button",
            }}
            anchorEl={anchorTime}
            open={time_open}
            onClose={handleTimeClose}
            TransitionComponent={Fade}
            PaperProps={{
              style: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: colors.primary[400],
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row-reverse",
                p: 1,
                m: 1,
                flexDirection: "column",
                borderRadius: 1,
                width: 340,
                p: 1,
              }}
            >
              <Box
                sx={{ flex: "1", textAlign: "center", paddingBottom: "0px" }}
              >
                <h3>Set Time</h3>
              </Box>
              <Slider
                min={0}
                max={1439}
                step={60}
                value={timeRange}
                onChange={handleTimeRangeChange}
                getAriaValueText={valuetext}
                sx={{
                  "& .MuiSlider-track": {
                    color: colors.grey[100],
                  },
                  "& .MuiSlider-thumb": {
                    color: colors.grey[100],
                    margin: "auto",
                  },
                }}
              />
              <Box
                sx={{
                  color: colors.grey[100],
                  margin: "auto",
                  paddingBottom: "20px",
                }}
              >
                <h3>{divtext(timeRange)}</h3>
              </Box>
              <Button
                sx={{
                  color: "#000000",
                  backgroundColor: colors.grey[100],
                  p: "10px", // set the padding to 10 pixels
                }}
                onClick={handleTimeClose}
              >
                <h3>Set</h3>
              </Button>
            </Box>
          </Menu>

          <Box
            id="eth-button"
            aria-controls={eth_open ? "eth-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={eth_open ? "true" : undefined}
            onClick={handleClick_eth}
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              alignItems: "center",
              bgcolor: "rgba(0,0,0,0.2)",
              fontSize: "20px",
              color: colors.grey[100],
              cursor: "pointer",
              "&:hover": { textDecoration: "underline" },
            }}
          >
            <SettingsEthernetIcon
              style={{ marginRight: "10px", color: colors.greenAccent[500] }}
            />{" "}
            {liValue === "wire" ? "Ethernet" : "Wireless"}
          </Box>

          <Menu
            id="eth-menu"
            MenuListProps={{
              "aria-labelledby": "eth-button",
            }}
            anchorEl={anchorEth}
            open={eth_open}
            onClose={handleClose}
            PaperProps={{
              style: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: colors.primary[400],
                border: 2,
              },
            }}
            transformOrigin={{ vertical: "top", horizontal: "center" }} // center the menu vertically and horizontally
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }} // center the menu under the anchor
          >
            <Box
              sx={{
                width: 140,
              }}
            >
              <MenuItem
                onClick={() => {
                  setValue("wire");
                  handleClose();
                }}
              >
                Ethernet
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setValue("wifi");
                  handleClose();
                }}
              >
                WiFi
              </MenuItem>
            </Box>
          </Menu>

          <Box
            id="ms-button"
            aria-controls={ms_open ? "ms-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={ms_open ? "true" : undefined}
            onClick={handleClick_ms}
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              alignItems: "center",
              bgcolor: "rgba(0,0,0,0.2)",
              fontSize: "20px",
              color: colors.grey[100],
              cursor: "pointer",
              "&:hover": { textDecoration: "underline" },
            }}
          >
            <SsidChartIcon
              style={{ marginRight: "10px", color: colors.greenAccent[500] }}
            />{" "}
            {liDlShow === "ms" ? "Latency" : "Bandwith"}
          </Box>

          <Menu
            id="ms-menu"
            MenuListProps={{
              "aria-labelledby": "ms-button",
            }}
            anchorEl={anchorMs}
            open={ms_open}
            onClose={handleClose}
            TransitionComponent={Fade}
            PaperProps={{
              style: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: colors.primary[400],
                border: 2,
              },
            }}
            transformOrigin={{ vertical: "top", horizontal: "center" }} // center the menu vertically and horizontally
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }} // center the menu under the anchor
          >
            <Box
              sx={{
                width: 140,
              }}
            >
              <MenuItem
                onClick={() => {
                  setDlShow("ms");
                  handleClose();
                }}
              >
                Latency
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setDlShow("dl");
                  handleClose();
                }}
              >
                Bandwith
              </MenuItem>
            </Box>
          </Menu>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            bgcolor: "rgba(0,0,0,0)",
            marginTop: "20px",
          }}
        ></Box>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              bgcolor: "rgba(0,0,0,0)",
              height: "50vh",
            }}
          >
            <CircularProgress sx={{ color: colors.greenAccent[500] }} />
          </Box>
        ) : (
          <Plot
            data={liLineData.map((trace, index) => ({
              ...trace,
              visible: index === 0 || index === 1 ? true : "legendonly",
            }))}
            layout={{
              paper_bgcolor: "rgba(0,0,0,0.2)",
              plot_bgcolor: "transparent",
              xaxis: {
                showgrid: false,
                nticks: liTicks,
                title: {
                  text: "Time",
                  font: {
                    color: colors.grey[100],
                  },
                },
                tickfont: {
                  color: colors.grey[100],
                },
              },
              yaxis: {
                showgrid: false,
                range: [0, maxY], 
                title: {
                  font: {
                    color: colors.grey[100],
                  },
                },
                tickfont: {
                  color: colors.grey[100],
                },
              },
              legend: {
                x: 0,
                y: 1.2,
                yref: "paper",
                font: { size: 20 },
                borderwidth: 2,
                bgcolor: "rgba(255, 255, 255, 0)",
                orientation: "h",
                font: {
                  color: colors.grey[100],
                },
              },
            }}
            style={{ marginTop: "0px", width: "100%", height: "100%" }}
          />
        )}

        {liValue === "wifi" ? (
          <>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "20px",
                color: colors.grey[100],
              }}
            >
              <h3>WiFi Signal</h3>
            </Box>

            <Plot
              data={liLineWiFiData?.map((trace, index) => ({
                ...trace,
                visible: true,
              }))}
              layout={{
                paper_bgcolor: "rgba(0,0,0,0.2)",
                plot_bgcolor: "transparent",
                xaxis: {
                  showgrid: false,
                  title: {
                    text: "Time",
                    font: {
                      color: colors.grey[100],
                    },
                  },
                  tickfont: {
                    color: colors.grey[100],
                  },
                },
                yaxis: {
                  showgrid: false,
                  title: {
                    font: {
                      color: colors.grey[100],
                    },
                  },
                  tickfont: {
                    color: colors.grey[100],
                  },
                },
                legend: {
                  x: 0,
                  y: 1.2,
                  yref: "paper",
                  font: { size: 20 },
                  borderwidth: 2,
                  bgcolor: "rgba(255, 255, 255, 0)",
                  orientation: "h",
                  font: {
                    color: colors.grey[100],
                  },
                },
              }}
              style={{ marginTop: "0px", width: "100%", height: "100%" }}
            />
          </>
        ) : (
          ""
        )}
      </div>
    </section>
  );
};

export default History;
