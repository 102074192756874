import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { Box, IconButton, Menu, MenuItem, useTheme } from "@mui/material";
import { signOut } from 'firebase/auth';
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import FormDialog from "../../components/modal_company";
import { auth } from "../../firebase_config";
import { ColorModeContext, tokens } from "../../theme";

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const navigate = useNavigate();
  const [getCompanyModal, setCompanyModal] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const Logout = async () => {
    try {
      signOut(auth);
      navigate("/");
    } catch (error) {
    }
  };

  return (
    <Box display="flex" justifyContent="flex-end" p={1}>
      <Box display="flex">
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
        {getCompanyModal && <FormDialog setCompanyModal={setCompanyModal} />}
        <IconButton
          aria-owns={anchorEl ? "simple-menu" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          onMouseOver={handleClick}
        >
          <PersonOutlinedIcon />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          MenuListProps={{ onMouseLeave: handleClose }}
        >
          <MenuItem>DEMO MODE</MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

export default Topbar;
