import { CircularProgress, useTheme } from "@mui/material";
import { ResponsiveLine } from "@nivo/line";
import React, { useContext, useEffect, useState } from 'react';
import { FirebaseContext } from "../data/provide_firestore";
import { tokens } from "../theme";

const LineChart = ({
  isCustomLineColors = false,
  isDashboard = false,
  scanner,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [liSql, setSql] = useState([
    { id: "wifi", color: "hsl(149, 70%, 50%)", data: [] },
    { id: "wire", color: "hsl(149, 70%, 50%)", data: [] },
  ]);
  const [liMax, setMax] = useState(10);
  const [liMin, setMin] = useState(0);
  const [loading, setLoading] = useState(true);

  const { liDataFire, liWifiDataFire } = useContext(FirebaseContext);

  const interval = 2; // time interval in minutes
  const numIntervals = 15; // number of intervals to show
  const currentTime = new Date();
  const Time = Array.from({ length: numIntervals }, (_, i) => {
    const time = new Date(currentTime.getTime() - i * interval * 60 * 1000);
    const minutes = time.getMinutes();
    const evenMinutes = Math.floor(minutes / 2) * 2;
    time.setMinutes(evenMinutes);
    return time.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  }).reverse();

  const Wire_func = async () => {
    if (liDataFire?.[scanner]?.length) {
      const validTimeSlots = Time;
      let wireData = validTimeSlots.map((t, index) => {
        const pingData = liDataFire?.[scanner].find(({ dt }) => dt[1] === t);
        const ping_value = pingData ? pingData.ping[4] : 0;
        return { x: t, y: ping_value };
      });
  
      // Remove zero values from the end of the array
      while (wireData.length > 0 && wireData[wireData.length - 1].y === 0) {
        wireData = wireData.slice(0, -1);
      }
      
      return wireData;
    }
  };
  
  const Wifi_func = async () => {
    if (liWifiDataFire?.[scanner]?.[0]) {
      const validTimeSlots = Time;
      let wifiData = validTimeSlots.map((t, index) => {
        const pingData = liWifiDataFire?.[scanner].find(
          ({ dt }) => dt[1] === t
        );
        const ping_value = pingData ? pingData.ping[4] : 0;
        return { x: t, y: ping_value };
      });
  
      // Remove zero values from the end of the array
      while (wifiData.length > 0 && wifiData[wifiData.length - 1].y === 0) {
        wifiData = wifiData.slice(0, -1);
      }
      
      return wifiData;
    }
  };
  

  useEffect(() => {
    const fetchDataAndSetState = async () => {
      const { graph, lowestY, highestY } = await fetchData();
      setSql(graph);
      setMax(highestY * 1.5);
      setLoading(false);
    };

    fetchDataAndSetState();
  }, [liDataFire, liWifiDataFire, scanner]);

  const fetchData = async () => {
    var Wifi = [];
    var Wire = [];

    // Wait for the liDataFire context to be fully loaded
    while (Object.keys(liDataFire).length === 0) {
      await new Promise((resolve) => setTimeout(resolve, 100));
    }

    const wireData = await Wire_func();
    if (wireData !== undefined) {
      Wire = wireData;
    }
    const wifiData = await Wifi_func();
    if (wifiData !== undefined) {
      Wifi = wifiData;
    }

    const combinedData = [...Wire, ...Wifi].filter((d) => d !== null);
    const lowestY = Math.min(...combinedData.map((d) => d.y));
    const highestY = Math.max(...combinedData.map((d) => d.y));

    const graph = [
      { id: "wifi", color: tokens("dark").blueAccent[300], data: Wifi },
      { id: "wire", color: tokens("dark").redAccent[300], data: Wire },
    ];

    return { graph, lowestY, highestY };
  };

  return (
    <>
      {loading ? (
        <CircularProgress /> // Show CircularProgress while data is loading
      ) : (
        <ResponsiveLine
          data={liSql}
          theme={{
            axis: {
              domain: {
                line: {
                  stroke: colors.grey[100],
                },
              },
              legend: {
                text: {
                  fill: colors.grey[100],
                },
              },
              ticks: {
                line: {
                  stroke: colors.grey[100],
                  strokeWidth: 1,
                },
                text: {
                  fill: colors.grey[100],
                },
              },
            },
            legends: {
              text: {
                fill: colors.grey[100],
              },
            },
            tooltip: {
              container: {
                color: colors.primary[500],
              },
            },
          }}
          colors={isDashboard ? { datum: "color" } : { scheme: "nivo" }} // added
          margin={{ top: 50, right: 30, bottom: 50, left: 30 }}
          xScale={{ type: "point" }}
          yScale={{
            type: "linear",
            min: liMin,
            max: liMax,
            reverse: false,
          }}
          yFormat=" >-.2f"
          curve="catmullRom"
          axisTop={null}
          axisRight={null}
          axisBottom={{
            orient: "bottom",
            tickSize: 0,
            tickPadding: 5,
            tickRotation: 0,
            legend: isDashboard ? undefined : "transportation", // added
            legendOffset: 36,
            legendPosition: "middle",
          }}
          axisLeft={{
            orient: "left",
            tickValues: 5, // added
            tickSize: 3,
            tickPadding: 5,
            tickRotation: 0,
            legend: isDashboard ? undefined : "count", // added
            legendOffset: -40,
            legendPosition: "middle",
          }}
          enableGridX={false}
          enableGridY={false}
          pointSize={0}
          pointColor={{ theme: "background" }}
          pointBorderWidth={2}
          pointBorderColor={{ from: "serieColor" }}
          pointLabelYOffset={-12}
          useMesh={true}
          legends={[]}
          animate={false}
        />
      )}
    </>
  );
};

export default LineChart;
