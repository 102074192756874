import {
    collection, documentId, getDocs, onSnapshot, query,
    where
} from "firebase/firestore";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useAuthValue } from "../AuthContext";
import { ScannersContext } from "../components/Dashboard";
import db from "../firebase_config";

export const FirebaseContext = createContext();

export const FirebaseProvider = (props) => {
  const [userCompany, setCompany] = useState("initial");
  const [trigger, settrigger] = useState(0);
  const [BWTrigger, setBWTrigger] = useState(0);
  const [getScanners, setScanners] = useState(null);
  const [getScannerInfo, setScannerInfo] = useState(null);
  const [liDataFire, setData] = useState(null);
  const [liUptimeWireFire, setUptimeWire] = useState(null);
  const [liUptimeWifiFire, setUptimeWifi] = useState(null);
  const [liWifiDataFire, setWifiData] = useState(null);
  const [liDevicesFire, setDevices] = useState(null);
  const [liAliasFire, setAlias] = useState(null);
  const [liSpeedFire, setSpeed] = useState(null);
  const [liWifiSpeedFire, setWifiSpeed] = useState(null);
  const [liHistoryFire, setHistory] = useState(null);
  const [liPenFire, setPen] = useState(null);
  const [liWifiScanFire, setWifiScan] = useState(null);
  var scanners = useContext(ScannersContext);
  var data = [];
  var data_uptime = [];
  var wifi_data_uptime = [];
  var wifiData = [];
  var history = [];
  var pen = [];
  var scan = [];
  var devices = [];
  var speed = [];
  var wifiSpeed = [];
  var alias = [];
  var ScannersInfo = []

  useEffect(() => {
    setCompany("Demo")
  }, []);

  useEffect(() => {
    var x = [];
    if (scanners) {
      scanners.map((scanner) => {
        x.push(scanner.scanner);
      });
      setScanners(x);
    }
  }, [scanners]);

  useEffect(() => {
    if (getScanners && getScanners.length) {
      const query_data = query(
        collection(db, "data"),
        where(documentId(), "in", getScanners)
      );
      const query_wifi_data = query(
        collection(db, "wifi_data"),
        where(documentId(), "in", getScanners)
      );
      const query_speed = query(
        collection(db, "speed"),
        where(documentId(), "in", getScanners)
      );
      const query_wifi_speed = query(
        collection(db, "wifi_speed"),
        where(documentId(), "in", getScanners)
      );
      const query_devices = query(
        collection(db, "devices"),
        where(documentId(), "in", getScanners)
      );
      const query_history = query(
        collection(db, "history"),
        where(documentId(), "in", getScanners)
      );
      const query_pen = query(
        collection(db, "pen"),
        where(documentId(), "in", getScanners)
      );
      const query_wifi_scan = query(
        collection(db, "wifi_scan"),
        where(documentId(), "in", getScanners)
      );
      const query_scanners = query(
        collection(db, "Scanners"),
        where(documentId(), "in", getScanners)
      );
      const unsubscribe_data = onSnapshot(query_data, (snapshot) => {
        snapshot.docChanges().map((change) => {
          var sorted_array = [];
          var sort = Object.entries(change.doc.data());
          sort.sort();
          sort = sort.slice(-15);
          let uptime = sort.slice(-1);
          sort.map((value) => {
            sorted_array.push(value[1]);
          });
          data[change.doc.id] = sorted_array;
          data_uptime[change.doc.id] = uptime?.[0]?.[0];
          settrigger(Math.random());
          setUptimeWire(data_uptime);
          setData(data);
        });
      });
      const unsubscribe_wifi_data = onSnapshot(query_wifi_data, (snapshot) => {
        snapshot.docChanges().map((change) => {
          var sorted_array = [];
          var sort = Object.entries(change.doc.data());
          sort.sort();
          sort = sort.slice(-15);
          let uptime = sort.slice(-1);
          sort.map((value) => {
            sorted_array.push(value[1]);
          });
          wifiData[change.doc.id] = sorted_array;
          wifi_data_uptime[change.doc.id] = uptime?.[0]?.[0];
          settrigger(Math.random());
          setUptimeWifi(wifi_data_uptime);
          setWifiData(wifiData);
        });
      });
      const unsubscribe_speed = onSnapshot(query_speed, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          var sorted_array = [];
          var sort = Object.entries(change.doc.data());
          sort.sort();
          sort.map((value) => {
            sorted_array.push(value[1]);
          });
          speed[change.doc.id] = sorted_array;
          settrigger(Math.random());
          setSpeed({...speed});
        });
      });
      const unsubscribe_wifi_speed = onSnapshot(query_wifi_speed, (snapshot) => {
          snapshot.docChanges().forEach((change) => {
            var sorted_array = [];
            var sort = Object.entries(change.doc.data());
            sort.sort();
            sort.map((value) => {
              sorted_array.push(value[1]);
            });
            wifiSpeed[change.doc.id] = sorted_array;
            settrigger(Math.random());
            setWifiSpeed({...wifiSpeed});
          });
        }
      );
      const unsubscribe_devices = onSnapshot(query_devices, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          var sorted_array = [];
          var sort = Object.entries(change.doc.data());
          sort.sort();
          sort.map((value) => {
            sorted_array.push(value[1]);
          });
          devices[change.doc.id] = sorted_array;
          settrigger(Math.random());
          setDevices(devices);
        });
      });
      const unsubscribe_history = onSnapshot(query_history, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          var sorted_array = [];
          var sort = Object.entries(change.doc.data());
          sort.sort();
          sort.map((value) => {
            sorted_array.push(value[1]);
          });
          history[change.doc.id] = sorted_array;
          settrigger(Math.random());
          setHistory(history);
        });
      });
      const unsubscribe_pen = onSnapshot(query_pen, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          var sorted_array = [];
          var sort = Object.entries(change.doc.data());
          sort.sort();
          sort.map((value) => {
            sorted_array.push(value[1]);
          });
          pen[change.doc.id] = sorted_array;
          settrigger(Math.random());
          setPen(pen);
        });
      });
      const unsubscribe_scanners = onSnapshot(query_scanners, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          ScannersInfo[change.doc.id] = change.doc.data();
          settrigger(Math.random());
          setScannerInfo(ScannersInfo);
        });
      });
      const unsubscribe_scan = onSnapshot(query_wifi_scan, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          var sorted_array = [];
          var sort = Object.entries(change.doc.data());
          sort.sort();
          if (sort?.[0]) {
            Object.entries(sort?.[0]?.[1]?.scan).map((value) => {
              sorted_array.push(value?.[1]);
            });

            var sorted_data = sorted_array.sort((a, b) => {
              if (parseInt(a.signal) > parseInt(b.signal)) {
                return -1;
              } else if (parseInt(a.signal) < parseInt(b.signal)) {
                return 1;
              }
              return 0;
            });
            scan[change.doc.id] = sorted_data;
            settrigger(Math.random());
            setWifiScan(scan);
          }
        });
      });
      if (scanners) {
        scanners.map((scanner) => {
          alias[scanner.scanner] = {
            scanner: scanner.scanner,
            alias: scanner.alias,
          };
          setAlias(alias);
        });
      }
    }
  }, [getScanners]);

  return (
    <FirebaseContext.Provider
      value={{
        liDataFire,
        liWifiDataFire,
        liUptimeWireFire,
        liUptimeWifiFire,
        liSpeedFire,
        liWifiSpeedFire,
        liAliasFire,
        liHistoryFire,
        liPenFire,
        liWifiScanFire,
        liDevicesFire,
        getScannerInfo,
        userCompany,
        trigger,
        BWTrigger
      }}
    >
      {props.children}
    </FirebaseContext.Provider>
  );
};
