import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import SummarizeIcon from '@mui/icons-material/Summarize';
import WifiIcon from '@mui/icons-material/Wifi';
import WifiTetheringIcon from '@mui/icons-material/WifiTethering';
import MediationOutlinedIcon from '@mui/icons-material/MediationOutlined';
import { Box, IconButton, Typography, useTheme, Input, Modal } from "@mui/material";
import moment from "moment";
import React, { useContext, useEffect, useState } from 'react';
import { Menu, MenuItem, ProSidebar, SubMenu } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Link } from "react-router-dom";
import FormAddScanner from '../../components/modal_addScanner';
import { FirebaseContext } from '../../data/provide_firestore';
import { tokens } from "../../theme";
import Wireshark from '../../components/modal_wireshark';
import { ToastContainer } from 'react-toastify';
import 'react-pro-sidebar/dist/css/styles.css';

const Item = ({ title, to, icon, select_data, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <MenuItem
      active={selected === select_data}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(select_data)}
      icon={icon}
    >
      <Typography variant="h6" sx={{ m: "5px 0 5px 0px" }}>
        {title}
      </Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = ({ probes, name, company, id }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const [iconColor, setIconColor] = useState([]);
  const [getAddScannerModal, setAddScannerModal] = useState(false);
  const [getWiresharkModal, setWiresharkModal] = useState(false);
  const [getCompany, setCompany] = useState(null);
  const [getWiresharkID, setWiresharkID] = useState(null);
  const [activeSubMenu, setActiveSubMenu] = useState(0);
  const {
    liDataFire,
    liUptimeWireFire,
    liUptimeWifiFire,
    liWifiDataFire,
    liHistoryFire,
    liPenFire,
    trigger,
  } = useContext(FirebaseContext);

  var currentdate = new Date();

  const handleClick = () => {
    setWiresharkModal(true);
  };

  const GetCompany = async (event) => {
    setCompany("DEMO");
  };


  const handleOpenChange = (index) => {
    if (activeSubMenu === index) {
      // if the clicked submenu is already open, close it
      setActiveSubMenu(null);
    } else {
      // otherwise, open the clicked submenu
      setActiveSubMenu(index);
    }
  };

  async function fetchLink() {
    if (probes) {
      const AllData = await Promise.all(
        probes.map((probe, i) => {
          var Color = [];
          let WifiTime = parseInt(liUptimeWifiFire[probe.scanner], 10);
          let WireTime = parseInt(liUptimeWireFire[probe.scanner], 10);
          if (Number.isNaN(WifiTime)) {
            WifiTime = 0;
          }
          if (Number.isNaN(WireTime)) {
            WireTime = 0;
          }
          let wire_waitdata = liDataFire[probe.scanner];
          let wifi_waitdata = liWifiDataFire[probe.scanner];
          const currentTime = Number(
            moment().subtract(3, "minutes").format("YYYYMMDDHHmm")
          );
          if (
            wire_waitdata[wire_waitdata.length - 1]?.dt.length ||
            wifi_waitdata[wifi_waitdata.length - 1]?.dt.length
          ) {
            let ethcolor = { color: colors.redAccent[500] };
            let wificolor = { color: colors.redAccent[500] };
            let mainColor = { color: colors.redAccent[500] };
          
            if (WireTime !== 0 && WireTime >= currentTime) {
              ethcolor = { color: colors.greenAccent[500] };
            }
            if (WifiTime !== 0 && WifiTime >= currentTime) {
              wificolor = { color: colors.greenAccent[500] };
            }
          
            if (
              (ethcolor.color === colors.greenAccent[500]) ||
              (wificolor.color === colors.greenAccent[500])
            ) {
              mainColor = { color: colors.greenAccent[500] };
            }
          
            Color.push({
              color: mainColor,
              ethcolor: ethcolor,
              wificolor: wificolor,
              alias: probe.alias,
              scanner: probe.scanner,
            });
          } else {
            Color.push({
              color: { color: colors.redAccent[500] },
              ethcolor: { color: colors.redAccent[500] },
              wificolor: { color: colors.redAccent[500] },
              fontSize: "22px",
              alias: probe.alias,
              scanner: probe.scanner,
            });
          }
          return Color;
        })
      );
      setIconColor(AllData);
    }
  }

  useEffect(() => {
    GetCompany();
    fetchLink();
  }, [trigger]);

  useEffect(() => {
    if (id) {
      probes.map((rows) => {
        if (rows.scanner === id) {
          if (rows.alias) {
            setSelected(rows.alias);
          } else if (!rows.alias) {
            setSelected(rows.scanner);
          }
        }
      });
    }
  }, [probes, id]);

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3" color={colors.grey[100]}>
                  {getCompany}
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
              ></Box>
              <Box textAlign="center">
                <Typography variant="h4" color={colors.greenAccent[500]}>
                  Network Analyzer
                </Typography>
              </Box>
            </Box>
          )}

          <Box
            paddingLeft={isCollapsed ? undefined : "10%"}
            style={{
              backgroundImage: `url(https://analyzer.hs7.se/HS_white_op3.png)`,
              backgroundSize: "250%",
              backgroundPositionX: "left",
              backgroundPositionY: "top",
              backgroundRepeat: "no-repeat",
            }}
          >
            <Item
              key="Dashboard"
              select_data="Dashboard"
              title="Dashboard"
              to="/dashboard"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography
              variant="h6"
              color={colors.grey[100]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Scanners
            </Typography>

            {iconColor.map((y, index) => ({
              ...(y[0].alias ? (
                <SubMenu
                key={y[0].scanner + "4"}
                open={activeSubMenu === index}
                title={y[0].alias}
                icon={<SsidChartIcon sx={y[0].color} />}
                style={{ color: colors.grey[100] }}
                onOpenChange={() => handleOpenChange(index)}
              >
                  <Item
                    key={y[0].scanner}
                    select_data={y[0].scanner + index + index }
                    title="Ethernet Data"
                    to={"/" + y[0].scanner}
                    icon={<SettingsEthernetIcon sx={y[0].ethcolor} />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    key={y[0].scanner + "-wifi"}
                    select_data={y[0].scanner + index + index + "-wifi"}
                    title="WiFi Data"
                    to={"/" + y[0].scanner + "/wifi"}
                    icon={<WifiIcon sx={y[0].wificolor} />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    key={y[0].scanner + "-wifiscan"}
                    select_data={y[0].scanner + index + "-wifiscan"}
                    title="WiFi Scan"
                    to={"/" + y[0].scanner + "/scan"}
                    icon={<WifiTetheringIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <MenuItem
                    key="wireshark"
                    style={{
                      color: colors.grey[100],
                    }}
                    onClick={() => {
                      setWiresharkID(y[0].scanner)
                      setWiresharkModal(true);
                    }}
                    icon={<MediationOutlinedIcon />}
                  >
                    <Typography variant="h6" sx={{ m: "5px 0 5px 0px" }}>
                      Wireshark
                    </Typography>
                  </MenuItem>
                  <Item
                    key={y[0].scanner + "-history"}
                    select_data={y[0].scanner + index + "-history"}
                    title="History"
                    to={"/" + y[0].scanner + "/history"}
                    icon={<SummarizeIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    key={y[0].scanner + "-pentest"}
                    select_data={y[0].scanner + index + "-pentest"}
                    title="Pentest Data"
                    to={"/" + y[0].scanner + "/pentest"}
                    icon={<AnalyticsOutlinedIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    key={y[0].scanner + "-settings"}
                    select_data={y[0].scanner + index + "-settings"}
                    title="Settings"
                    to={"/" + y[0].scanner + "/settings"}
                    icon={<SettingsIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </SubMenu>
              ) : (
                <SubMenu
                key={y[0].scanner + "3"}
                open={activeSubMenu === index}
                title={y[0].scanner}
                icon={<SsidChartIcon sx={y[0].color} />}
                style={{ color: colors.grey[100] }}
                onOpenChange={() => handleOpenChange(index)}
              >
                  <Item
                    key={y[0].scanner + "2"}
                    select_data={y[0].scanner + index}
                    title="Ethernet Data"
                    to={"/" + y[0].scanner}
                    icon={<SettingsEthernetIcon sx={y[0].ethcolor} />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    key={y[0].scanner + "-wifi2"}
                    select_data={y[0].scanner + index + "-wifi"}
                    title="WiFi Data"
                    to={"/" + y[0].scanner + "/wifi"}
                    icon={<WifiIcon sx={y[0].wificolor} />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    key={y[0].scanner + "-wifiscan2"}
                    select_data={y[0].scanner + index + "-wifiscan"}
                    title="WiFi Scan"
                    to={"/" + y[0].scanner + "/scan"}
                    icon={<WifiTetheringIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <MenuItem
                    key={y[0].scanner + "-wireshark2"}
                    style={{
                      color: colors.grey[100],
                    }}
                    onClick={() => {
                      setWiresharkID(y[0].scanner)
                      setWiresharkModal(true);
                    }}
                    icon={<MediationOutlinedIcon />}
                  >
                    <Typography variant="h6" sx={{ m: "5px 0 5px 0px" }}>
                      Wireshark
                    </Typography>
                  </MenuItem>
                  <Item
                    key={y[0].scanner + "-history"}
                    select_data={y[0].scanner + index + "-history"}
                    title="History"
                    to={"/" + y[0].scanner + "/history"}
                    icon={<SummarizeIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    key={y[0].scanner + "-pentest2"}
                    select_data={y[0].scanner + index + "-pentest"}
                    title="Pentest Data"
                    to={"/" + y[0].scanner + "/pentest"}
                    icon={<AnalyticsOutlinedIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    key={y[0].scanner + "-settings2"}
                    select_data={y[0].scanner + index + "-settings"}
                    title="Settings"
                    to={"/" + y[0].scanner + "/settings"}
                    icon={<SettingsIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </SubMenu>
              )),
            }))}

          </Box>
        </Menu>
        <ToastContainer />
        {getAddScannerModal && (
          <FormAddScanner setAddScannerModal={setAddScannerModal} />
        )}
        {getWiresharkModal && (
          <Wireshark setWiresharkModal={setWiresharkModal} id={id} wid={getWiresharkID} />
        )}

        <div
          style={{
            backgroundImage: `url(https://analyzer.hs7.se/HS_white_op1.png)`,
            backgroundSize: "70%",
            backgroundPositionX: "25%",
            backgroundRepeat: "no-repeat",
            position: "absolute",
            bottom: "20px",
            left: "20px",
          }}
        ></div>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
