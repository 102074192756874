import { CssBaseline, ThemeProvider } from "@mui/material";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { collection, getDocs, query, where } from 'firebase/firestore';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FirebaseContext, FirebaseProvider } from '../data/provide_firestore';
import db from '../firebase_config';
import "../index.css";
import Overview from '../scenes/dashboard';
import Wifi_Overview from '../scenes/dashboard/index_wifi';
import History from '../scenes/dashboard/scanner_history';
import Pen from '../scenes/dashboard/scanner_pen';
import Scan from '../scenes/dashboard/scanner_scan';
import Settings from '../scenes/dashboard/scanner_settings';
import Sidebar from "../scenes/global/Sidebar";
import Topbar from "../scenes/global/Topbar";
import { ColorModeContext, useMode } from "../theme";
import FormDialog from './modal_company';

export const ScannersContext = createContext();

const Dashboard = ({ site, value }) => {
  const [theme, colorMode] = useMode();
  const [name, setName] = useState([]);
  const [token, setToken] = useState(null);
  const [getUid, setUid] = useState("");
  const [expire, setExpire] = useState("");
  const [company, setCompany] = useState("");
  const [companyModal, setCompanyModal] = useState(false);
  const [scanners, setScanners] = useState([]);
  const [isSidebar, setIsSidebar] = useState(true);
  const navigate = useNavigate();
  let { id } = useParams();
  const { userCompany } = useContext(FirebaseContext);

  useEffect(() => {
    if (useContext != "initial") refreshToken();
  }, [userCompany]);

  const refreshToken = async () => {
      setCompany("Demo");
      setToken(true);
      const tempScanners = []
      tempScanners.push({
            scanner: "hs2001",
            alias: "Demo Room 205",
          });
          tempScanners.push({
            scanner: "hs2001",
            alias: "Demo Office Floor 4",
          });
      setScanners(tempScanners);
  };

  const isIdValid = (id, scanners) => {
    return scanners.some((scanner) => scanner.scanner === id);
  };

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ScannersContext.Provider value={scanners}>
        <FirebaseProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            {token == false && <FormDialog setCompanyModal={setCompanyModal} />}
            <div className="app">
              <Sidebar
                isSidebar={isSidebar}
                name={name}
                probes={scanners}
                company={company}
                id={id}
              />
              <main className="content">
                <Topbar setIsSidebar={setIsSidebar} />
                {((id === "dashboard") || isIdValid(id, scanners)) ? (
                  <>
                {site === "settings" && (
                  <Settings
                    company={company}
                    id={id}
                    site={"settings"}
                  ></Settings>
                )}
                {site === "pen" && (
                  <Pen company={company} id={id} site={"pen"}></Pen>
                )}
                {site === "scan" && (
                  <Scan company={company} id={id} site={"scan"}></Scan>
                )}
                {site === "dashboard" && (
                  <Overview
                    company={userCompany}
                    scanner={id}
                    scannerdata={scanners}
                  ></Overview>
                )}
                {site === "wifi" && (
                  <Wifi_Overview
                    company={userCompany}
                    scanner={id}
                    scannerdata={scanners}
                  ></Wifi_Overview>
                )}
                {site === "history" && (
                  <History
                    company={company}
                    id={id}
                    scannerdata={scanners}
                  ></History>
                )}
                </>
                ) : (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <h2>No access</h2>
                  </div>
                )}
              </main>
            </div>
          </ThemeProvider>
        </FirebaseProvider>
      </ScannersContext.Provider>
    </ColorModeContext.Provider>
  );
};

export default Dashboard;
