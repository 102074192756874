import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { onAuthStateChanged } from 'firebase/auth';
import { useState } from 'react';
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import { FirebaseProvider } from './data/provide_firestore';
import { auth } from './firebase_config';
import PrivateRoute from './PrivateRoute';
import Profile from './Profile';
import VerifyEmail from './VerifyEmail';
import { ToastContainer } from 'react-toastify';

function App() {
  const [currentUser, setCurrentUser] = useState("Demo");


  onAuthStateChanged(auth, (user) => {
    setCurrentUser(user);
  });

  if (currentUser === "init") {
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    );
  }

  return (
      <FirebaseProvider>
      <ToastContainer />
        <Routes>
          <Route
            exact
            path="/"
            element={
            <Navigate to="/dashboard" replace />
            }
          />
            <Route
              path="/:id"
              element={
                <Dashboard
                  site={"dashboard"}
                />
              }
            />
            <Route
              path="/:id/wifi"
              element={
                <Dashboard site={"wifi"} />
              }
            />

            <Route
              path="/:id/pentest"
              element={
                
                  <Dashboard site={"pen"} />

              }
            />

            <Route
              path="/:id/scan"
              element={
                
                  <Dashboard site={"scan"} />

              }
            />

            <Route
              path="/:id/history"
              element={
                
                  <Dashboard site={"history"} />

              }
            />

            <Route
              path="/:id/settings"
              element={
                
                  <Dashboard site={"settings"} />

              }
            />

        </Routes>
      </FirebaseProvider>
  );
}

export default App;
