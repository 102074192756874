import { Switch, useTheme } from "@mui/material";
import { collection, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import React, { useEffect, useState } from 'react';
import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebase_config";
import { useNavigate } from 'react-router-dom';
import db from '../../firebase_config';
import TimeRangePicker from "../../components/wireshark_date";
import { tokens } from "../../theme";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { FormControl, InputLabel, OutlinedInput, FormHelperText, FormControlLabel, Typography, FormLabel, RadioGroup, Radio, Select, MenuItem } from '@mui/material';
import { styled } from '@mui/system';
import { toast } from 'react-toastify';

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => {
  const colors = tokens(theme.palette.mode);
  return {
    '& .MuiOutlinedInput-input': {
      color: colors.grey[100],
    },
    '& .MuiInputLabel-outlined': {
      color: colors.grey[100],
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px',
      borderColor: colors.grey[100],
    },
    width: '100%',
    marginBottom: '40px',
  };
});

const StyledRadio = styled(Radio)(({ theme }) => ({
  '&.Mui-checked': {
    color: theme.palette.grey[100],
  },
  color: theme.palette.grey[200],
}));

const Settings = ({ company, id, site }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [liid, setId] = useState("");
  const [Alias, setAlias] = useState(null);
  const [Email, setEmail] = useState(null);
  const [PSK, setPSK] = useState(null);
  const [UNAME, setUNAME] = useState(null);
  const [UPASS, setUPASS] = useState(null);
  const [SSID, setSSID] = useState(null);
  const [BSS, setBSS] = useState(null);
  const [IP, setIP] = useState("8.8.3.4");
  const [Scanners, setScanners] = useState([]);
  const [msg, setMsg] = useState("");
  const [warning_msg, setwarning_Msg] = useState("");
  const navigate = useNavigate();
  const [checked, setChecked] = useState(null);
  const [visible, setVisible] = useState(false);
  const [getConnected, setConnected] = useState(false);
  const [inputSSID, setInputSSID] = useState(true);
  const [APspec, setAPspec] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [SSIDarray, setSSIDarray] = useState([]);
  const [auth, setAuth] = useState("OPEN");

  const handleAuthChange = (event) => {
    setAuth(event.target.value);
  };
  
  const handleInputSSIDChange = (event) => {
    setSSID(null)
    setInputSSID(event.target.value === 'true');
  };
  const handleSetSSIDChange = (event) => {
    const selectedSSID = event.target.value;
    
    // Find the selected object in the array
    const selectedObject = SSIDarray.find(item => item.ssid === selectedSSID);
    
    if (selectedObject) {
      if (selectedObject.auth_suite.toUpperCase() === "IEEE") {
        setAuth("PEAP");
      } else {
        setAuth(selectedObject.auth_suite.toUpperCase());
      }
    }
    
    setSSID(selectedSSID);
  };
  
  
  useEffect(() => {
    GetAlias();
    fetchData();
  }, [id, site, company]);

  useEffect(() => {
    if (checked) {
      if (checked[0] === false) {
        setVisible(false);
      } else {
        setVisible(true);
      }
    }
  }, [checked, id, site]);

  const fetchData = async () => {
    const response = await bigQuery();
    if (response?.[0]?.[0]?.data) {
      const data = JSON.parse(response[0][0].data);
      data.sort((a, b) => {
        return parseInt(b.signal) - parseInt(a.signal);
      });
    
    const uniqueSSIDs = data.reduce((acc, item) => {
      if (item && item.ssid && !Array.from(acc).find(i => i.ssid === item.ssid)) {
        acc.add({ssid: item.ssid, auth_suite: item.auth_suite});
      }
      return acc;
    }, new Set());

    
      const uniqueSSIDArray = Array.from(uniqueSSIDs);
      setSSIDarray(uniqueSSIDArray)
    }
    
    
  };

  const bigQuery = async () => {
    const doBigQuery = httpsCallable(functions, "get_scan");
    var data = {
      scanner: id,
    };
    const fullResult = await doBigQuery(data);
    return fullResult.data;
  };

  function validateInput(value) {
    const macAddressRegex = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
    return macAddressRegex.test(value) || value === '';
  }

  const updateFieldChanged = (i) => (e) => {
    let name = e.target.name;

    if (name === "alias") {
      setAlias(e.target.value);
    } else if (name === "email") {
      setEmail(e.target.value);
    } else if (name === "ssid") {
      setSSID(e.target.value);
    } else if (name === "psk") {
      setPSK(e.target.value);
    } else if (name === "uname") {
      setUNAME(e.target.value);
    } else if (name === "upass") {
      setUPASS(e.target.value);
    } else if (name === "ip") {
      setIP(e.target.value);
    } else if (name === "bss") {
      setBSS(e.target.value);
      setIsValid(validateInput(e.target.value));
    }
  };

  const handleSwitchChange = (event) => {
    if(event.target.name === "connect"){
      setConnected(event.target.checked)
      if(event.target.checked === false){
        setAPspec(event.target.checked);
        setBSS(null)
        setIsValid(true)
      }
      
    }
    else{
      setAPspec(event.target.checked);
    }
    
  };

  const GetAlias = async () => {
    let scanners = [];
    let aliases = [];
    let alerts = [];
    try {
      if (company) {
        var dataRef = [];
        if (company.toLowerCase() === "admin") {
          dataRef = query(collection(db, "Scanners"));
        } else {
          dataRef = query(
            collection(db, "Scanners"),
            where("company", "==", company.toLowerCase())
          );
        }
        const dataSnap = await getDocs(dataRef);
        dataSnap.docs.map((y) => {
          if (y.data().scanner === id) {
            alerts.push(
              Boolean(y.data().alert_on),
              Boolean(y.data().alert_ms),
              Boolean(y.data().alert_lost)
            );
            setEmail(y.data().email ? y.data().email : "");
            setSSID(y.data().ssid ? y.data().ssid : "");
            setConnected(y.data().ssid ? true : false);
            setPSK(y.data().psk ? y.data().psk : "");
            setUNAME(y.data().uname ? y.data().uname : "");
            setIP(y.data().customIP ? y.data().customIP : "8.8.4.4");
            setBSS(y.data().bss ? y.data().bss : "", setAPspec(false));
            if(y.data().bss){
              setAPspec(true)
            }
            if(y.data().psk){
              setAuth("PSK")
            }
            if(y.data().uname){
              setAuth("PEAP")
            }
            aliases = y.data().alias;
            scanners.push(y.data().scanner);
          }
          setChecked(alerts);
        });
        setScanners(scanners);
        setAlias(aliases);
      }
    } catch (error) {
      console.error();
    }
  };

  const submitSettings = async (event) => {
    event.preventDefault();
    toast.warn('Demo Mode!', {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
  };

  return (
    <section>
      <div
        className="pen_box"
        style={{
          backgroundColor: colors.primary[400],
          backgroundImage: `url(https://analyzer.hs7.se/HS_white_op2.png)`,
          backgroundSize: "70%",
          backgroundPositionX: "25%",
          backgroundRepeat: "no-repeat",
          paddingBottom: "50px",
        }}
      >
        <div>
          <div>
            <h3
              className="settings has-text-centered"
              style={{ color: colors.greenAccent[100], fontSize:"30px" }}
            >
              Settings
            </h3>
            {checked && (
              <form name="form_settings" onSubmit={submitSettings}>
                <hr style={{ width: "10%", marginTop: "-20px" }}></hr>
                <p className="has-text-centered warning">{warning_msg}</p>
                <p className="has-text-centered valid">{msg}</p>

                <div style={{marginLeft: "50px", marginRight: "50px" }}>
                  <div style={{backgroundColor: "rgba(0,0,0,0.01)", padding: "0%", width: "30%"}}>
                <h3 className=" settings" style={{ color: colors.blueAccent[100], marginBottom:"20px" }}>
                  WiFi
                </h3>
                <div style={{marginBottom: "10px"}}></div>
                <FormControlLabel style={{ marginTop: "10px"}}
                control={
                <Switch
                  checked={getConnected}
                  onChange={handleSwitchChange}
                  name="connect"
                  color="primary"
                  sx={{
                    '& .MuiSwitch-thumb': {
                      backgroundColor: getConnected ? colors.greenAccent[400] : colors.grey[400],
                    },
                    '& .MuiSwitch-track': {
                      backgroundColor: getConnected ? colors.redAccent[400] : colors.grey[400],
                    },
                    '&:hover .MuiSwitch-thumb': {
                      backgroundColor: getConnected ? colors.greenAccent[400] : colors.grey[400],
                    },
                    '&:hover .MuiSwitch-track': {
                      backgroundColor: getConnected ? colors.grey[100] : colors.grey[400],
                    },
                  }}
                />
                        }
        label={
          <Typography style={{ color: colors.grey[100] }}>Connect to Wifi</Typography>
        }
      />      <Typography
      style={{
        color: getConnected ? colors.greenAccent[100] : colors.redAccent[100], marginBottom: "7px"
      }}
    >
      {getConnected ? '' : 'Disconnected'}
    </Typography>
                {getConnected && 
                <>
                <FormControl>
                  <FormLabel style={{ color: colors.grey[100], marginBottom: "10px", }} id="auth-radio-buttons-group-label"></FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={inputSSID}
                    onChange={handleInputSSIDChange}
                  >
                <FormControlLabel style={{ color: colors.grey[200],  marginLeft: "0px" }} value="true" control={<StyledRadio />} label="Auto" />
                <FormControlLabel style={{ color: colors.grey[200] }} value="false" control={<StyledRadio />} label="Manual" />

                  </RadioGroup>
                </FormControl>
                {inputSSID ?
                (
                <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
                  <InputLabel
                    htmlFor="ssid"
                    sx={{
                      color: colors.grey[100],
                      '&.Mui-focused': {
                        color: colors.grey[100],
                      },
                      '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
                        color: colors.grey[100],
                      },
                      '&.MuiInputLabel-outlined': {
                        color: 'grey',
                      },
                    }}
                  >
                    SSID
                  </InputLabel>
                  <Select
                    value={SSID}
                    onChange={handleSetSSIDChange}
                    //onChange={(event) => setSSID(event.target.value)}
                    label="SSID"
                    id="ssid"
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        color: colors.grey[100],
                      },
                      '& .MuiOutlinedInput-input::placeholder': {
                        color: colors.grey[100],
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: colors.grey[100],
                      },
                      '&:hover > .MuiOutlinedInput-notchedOutline': {
                        borderColor: colors.grey[100],
                      },
                      '&.Mui-focused > .MuiOutlinedInput-notchedOutline': {
                        borderColor: colors.grey[100],
                      },
                      '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
                        color: colors.grey[100],
                      },
                    }}
                  >
                  {SSIDarray.map((option, index) => (
                    <MenuItem key={index} value={option.ssid}>
                      {`${option.ssid} (${option.auth_suite === "IEEE" ? "802.1x" : option.auth_suite})`}
                    </MenuItem>
                  ))}


                  </Select>
                </FormControl>

                ) : (
                  <>
                  <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
                  <InputLabel
                    htmlFor="ssid"
                    sx={{
                      color: colors.grey[100],
                      '&.Mui-focused': {
                        color: colors.grey[100],
                      },
                      '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
                        color: colors.grey[100],
                      },
                      '&.MuiInputLabel-outlined': {
                        color: 'grey',
                      },
                      
                    }}
                  >
                    SSID
                  </InputLabel>
                  <OutlinedInput
                    id="SSID"
                    name="SSID"
                    type={SSID}
                    value={SSID}
                    disabled={true}
                    onChange={(event, newValue) => setSSID(newValue)}
                    label="SSID"
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        color: colors.grey[100],
                      },
                      '& .MuiOutlinedInput-input::placeholder': {
                        color: colors.grey[100],
                      },          
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: colors.grey[100],
                      },
                      '&:hover > .MuiOutlinedInput-notchedOutline': {
                        borderColor: colors.grey[100],
                      },
                      '&.Mui-focused > .MuiOutlinedInput-notchedOutline': {
                        borderColor: colors.grey[100],
                      },
                      '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
                        color: colors.grey[100],
                      },
                    }}
                  />
                <FormHelperText sx={{ color: colors.grey[100], fontSize:"16px"}}>
                </FormHelperText>
                </FormControl>
                <div style={{marginTop: "0px"}}></div>

                <FormControl>
                <FormLabel style={{ color: colors.grey[100], marginBottom: "10px", }} id="auth-radio-buttons-group-label"></FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={auth}
                  onChange={handleAuthChange}
                >
              <FormControlLabel style={{ color: colors.grey[200],  marginLeft: "0px" }} value="OPEN" control={<StyledRadio />} label="OPEN" />
              <FormControlLabel style={{ color: colors.grey[200] }} value="PSK" control={<StyledRadio />} label="PSK" />
              <FormControlLabel style={{ color: colors.grey[200] }} value="PEAP" control={<StyledRadio />} label="PEAP" />

                </RadioGroup>
              </FormControl>
              </>
                )}

                
                {auth == "PSK" && 
                <>
                <div style={{marginTop: "10px"}}></div>
                  <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
                        <InputLabel
                          htmlFor="psk"
                          sx={{
                            color: colors.grey[100],
                            '&.Mui-focused': {
                              color: colors.grey[100],
                            },
                            '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
                              color: colors.grey[100],
                            },
                            '&.MuiInputLabel-outlined': {
                              color: 'grey',
                            },
                            
                          }}
                        >
                          PSK
                        </InputLabel>
                        <OutlinedInput
                          id="psk"
                          name="psk"
                          type="password"
                          value={PSK}
                          disabled={true}
                          onChange={updateFieldChanged()}
                          label="PSK"
                          sx={{
                            "& .MuiOutlinedInput-input": {
                              color: colors.grey[100],
                            },
                            '& .MuiOutlinedInput-input::placeholder': {
                              color: colors.grey[100],
                            },          
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderColor: colors.grey[100],
                            },
                            '&:hover > .MuiOutlinedInput-notchedOutline': {
                              borderColor: colors.grey[100],
                            },
                            '&.Mui-focused > .MuiOutlinedInput-notchedOutline': {
                              borderColor: colors.grey[100],
                            },
                            '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
                              color: colors.grey[100],
                            },
                          }}
                        />
                      <FormHelperText sx={{ color: colors.grey[100], fontSize:"16px"}}>
                      </FormHelperText>
                      </FormControl>
                      </>
                      }
                      {auth == "PEAP" && 
                      <>
                      <div style={{marginTop: "10px"}}></div>
                      <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
                        <InputLabel
                          htmlFor="uname"
                          sx={{
                            color: colors.grey[100],
                            '&.Mui-focused': {
                              color: colors.grey[100],
                            },
                            '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
                              color: colors.grey[100],
                            },
                            '&.MuiInputLabel-outlined': {
                              color: 'grey',
                            },
                            
                          }}
                        >
                          Username
                        </InputLabel>
                        <OutlinedInput
                          id="uname"
                          name="uname"
                          type={UNAME}
                          value={UNAME}
                          disabled={true}
                          onChange={updateFieldChanged()}
                          label="UNAME"
                          sx={{
                            "& .MuiOutlinedInput-input": {
                              color: colors.grey[100],
                            },
                            '& .MuiOutlinedInput-input::placeholder': {
                              color: colors.grey[100],
                            },          
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderColor: colors.grey[100],
                            },
                            '&:hover > .MuiOutlinedInput-notchedOutline': {
                              borderColor: colors.grey[100],
                            },
                            '&.Mui-focused > .MuiOutlinedInput-notchedOutline': {
                              borderColor: colors.grey[100],
                            },
                            '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
                              color: colors.grey[100],
                            },
                          }}
                        />
                      <FormHelperText sx={{ color: colors.grey[100], fontSize:"16px"}}>
                      </FormHelperText>
                      </FormControl>
                      <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
                      <InputLabel
                        htmlFor="upass"
                        sx={{
                          color: colors.grey[100],
                          '&.Mui-focused': {
                            color: colors.grey[100],
                          },
                          '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
                            color: colors.grey[100],
                          },
                          '&.MuiInputLabel-outlined': {
                            color: 'grey',
                          },
                          
                        }}
                      >
                        Password
                      </InputLabel>
                      <OutlinedInput
                        id="upass"
                        name="upass"
                        type="password"
                        value={UPASS}
                        disabled={true}
                        onChange={updateFieldChanged()}
                        label="UPASS"
                        sx={{
                          "& .MuiOutlinedInput-input": {
                            color: colors.grey[100],
                          },
                          '& .MuiOutlinedInput-input::placeholder': {
                            color: colors.grey[100],
                          },          
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: colors.grey[100],
                          },
                          '&:hover > .MuiOutlinedInput-notchedOutline': {
                            borderColor: colors.grey[100],
                          },
                          '&.Mui-focused > .MuiOutlinedInput-notchedOutline': {
                            borderColor: colors.grey[100],
                          },
                          '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
                            color: colors.grey[100],
                          },
                        }}
                      />
                    <FormHelperText sx={{ color: colors.grey[100], fontSize:"16px"}}>
                    </FormHelperText>
                    </FormControl>
                    </>
                  }
                  <br></br>
                  <FormControlLabel style={{ marginTop: "10px"}}
        control={
          <Switch
            checked={APspec}
            onChange={handleSwitchChange}
            name="showListSwitch"
            color="primary"
            sx={{
              '& .MuiSwitch-thumb': {
                backgroundColor: APspec ? colors.grey[100] : colors.grey[400],
              },
              '& .MuiSwitch-track': {
                backgroundColor: APspec ? colors.grey[100] : colors.grey[400],
              },
              '&:hover .MuiSwitch-thumb': {
                backgroundColor: APspec ? colors.grey[100] : colors.grey[400],
              },
              '&:hover .MuiSwitch-track': {
                backgroundColor: APspec ? colors.grey[100] : colors.grey[400],
              },
            }}
          />
        }
        label={
          <Typography style={{ color: colors.grey[100] }}>Specify AP</Typography>
        }
      />
      <br></br>

      {APspec && 
      <>
      <FormControl sx={{ width: "100%" }} variant="outlined">
        <InputLabel
          htmlFor="bss"
          sx={{
            marginTop: "5px",
            color: colors.grey[100],
            '&.Mui-focused': {
              color: colors.grey[100],
            },
            '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
              color: colors.grey[100],
            },
            '&.MuiInputLabel-outlined': {
              color: 'grey',
            },
          }}
        >
          AP
        </InputLabel>
        <OutlinedInput
          id="bss"
          name="bss"
          type={BSS}
          value={BSS}
          disabled={true}
          onChange={updateFieldChanged()}
          label="AP"
          sx={{
            "& .MuiOutlinedInput-input": {
              color: colors.grey[100],
            },
            '& .MuiOutlinedInput-input::placeholder': {
              color: colors.grey[100],
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: colors.grey[100],
            },
            '&:hover > .MuiOutlinedInput-notchedOutline': {
              borderColor: colors.grey[100],
            },
            '&.Mui-focused > .MuiOutlinedInput-notchedOutline': {
              borderColor: colors.grey[100],
            },
            '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
              color: colors.grey[100],
            },
          }}
        />
        {!isValid && (
        <FormHelperText sx={{ color: 'red', fontSize:"16px"}}>
          MAC format: aa:bb:cc:11:22:33
        </FormHelperText>
      )}
      </FormControl>
      <div style={{marginBottom: "20px"}}></div>
      </>
      }
                            </>
                        }
      <br></br>
      </div>

      <hr style={{marginTop:"0px"}}></hr>
      <div style={{backgroundColor: "rgba(0,0,0,0.01)", padding: "0%", width: "30%"}}>
      <h3
                    className="settings"
                    style={{ color: colors.blueAccent[100], marginTop: "20px" }}
                  >
                    Trace Custom IP
                  </h3>
<FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
      <InputLabel
        htmlFor="psk"
        sx={{
          color: colors.grey[100],
          '&.Mui-focused': {
            color: colors.grey[100],
          },
          '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
            color: colors.grey[100],
          },
          '&.MuiInputLabel-outlined': {
            color: 'grey',
          },
          
        }}
      >
        IP
      </InputLabel>
      <OutlinedInput
        id="ip"
        name="ip"
        type={IP}
        value={IP}
        onChange={updateFieldChanged()}
        label="IP"
        sx={{
          "& .MuiOutlinedInput-input": {
            color: colors.grey[100],
          },
          '& .MuiOutlinedInput-input::placeholder': {
            color: colors.grey[100],
          },          
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.grey[100],
          },
          '&:hover > .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.grey[100],
          },
          '&.Mui-focused > .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.grey[100],
          },
          '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
            color: colors.grey[100],
          },
        }}
      />
    <FormHelperText sx={{ color: colors.grey[100], fontSize:"16px"}}>
      Type an IP that you cant to trace
    </FormHelperText>
    </FormControl>
    </div>
      <hr style={{marginTop:"40px", width: "100%"}}></hr>
      <div style={{backgroundColor: "rgba(0,0,0,0.01)", padding: "0%", width: "30%"}}>
                  <h3
                    className="settings"
                    style={{ color: colors.blueAccent[100], marginTop: "20px" }}
                  >
                    Alias
                  </h3>
                  <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
                    <InputLabel
                      htmlFor="psk"
                      sx={{
                        color: colors.grey[100],
                        '&.Mui-focused': {
                          color: colors.grey[100],
                        },
                        '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
                          color: colors.grey[100],
                        },
                        '&.MuiInputLabel-outlined': {
                          color: 'grey',
                        },
                        
                      }}
                    >
                      {id}
                    </InputLabel>
                    <OutlinedInput
                      id="alias"
                      name="alias"
                      type={Alias}
                      value={Alias}
                      disabled={true}
                      onChange={updateFieldChanged()}
                      label="Alias"
                      sx={{
                        "& .MuiOutlinedInput-input": {
                          color: colors.grey[100],
                        },
                        '& .MuiOutlinedInput-input::placeholder': {
                          color: colors.grey[100],
                        },          
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: colors.grey[100],
                        },
                        '&:hover > .MuiOutlinedInput-notchedOutline': {
                          borderColor: colors.grey[100],
                        },
                        '&.Mui-focused > .MuiOutlinedInput-notchedOutline': {
                          borderColor: colors.grey[100],
                        },
                        '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
                          color: colors.grey[100],
                        },
                      }}
                    />
                  <FormHelperText sx={{ color: colors.grey[100], fontSize:"16px"}}>
                    Give the Analyzer an Alias
                  </FormHelperText>
                  </FormControl>
                  </div>
                  {/* <TimeRangePicker /> */}


                  {/* !!! ALERT IS NOT ACTIVE !!!
                <hr style={{marginTop:"20px"}}></hr>
                <h3 className=" settings" style={{ color: colors.redAccent[100] }}>
                  Alert
                </h3>
                <div className="controls">
                  <h4 className="settings" style={{ color: colors.grey[100] }}>
                    Turn on alert:
                    <Switch
                      checked={checked[0]}
                      name="0"
                      onChange={updateSwitchChanged()}
                      color="secondary"
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </h4>
                  {visible && (
                    <div id="alert_hidden">
                      <h4
                        className="settings"
                        style={{ color: colors.grey[100] }}
                      >
                        Alert high ms:
                        <Switch
                          id="alert_ms"
                          checked={checked[1]}
                          name="1"
                          onChange={updateSwitchChanged()}
                          inputProps={{ "aria-label": "controlled" }}
                          color="secondary"
                        />
                      </h4>
                      <h4
                        className="settings"
                        style={{ color: colors.grey[100] }}
                      >
                        Alert scanner lost:
                        <Switch
                          id="alert_lost"
                          checked={checked[2]}
                          name="2"
                          onChange={updateSwitchChanged()}
                          inputProps={{ "aria-label": "controlled" }}
                          color="secondary"
                        />
                      </h4>
                    </div>
                  )}

                  <h4 className="settings" style={{ color: colors.grey[100] }}>
                    Email för alerts:{" "}
                  </h4>
                  <input
                    type="text"
                    style={{
                      width: "30%",
                      marginBottom: "20px",
                      marginTop: "-20px",
                    }}
                    className="input"
                    name="email"
                    value={Email}
                    onChange={updateFieldChanged()}
                  />

                  
                </div> */}
                <button className="button-46 is-success is-fullwidth">
                  Save
                </button>
                </div>
              </form>
              
            )}
          </div>
          </div>
      </div>
    </section>
  );
};

export default Settings;
